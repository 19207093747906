.page-container {
    padding: 10rem;
    font-family: 'Montserrat', sans-serif;
    height: 100vh; /* Changed to fill the entire screen */
  }
  
  .page-title {
    font-size: 5rem;
    font-weight: bold;
    text-align: left;
    margin-bottom: 1.5rem;
    margin-left: auto;
    margin-right: auto;
    max-width: 120rem; /* Aligns the page title with the users table */
  }
  
  .search-section {
    display: flex;
    justify-content: space-between; /* Aligns the search bar to the left and buttons to the right */
    margin-bottom: 1rem;
    margin-left: auto;
    margin-right: auto;
    max-width: 120rem; /* Aligns the search bar with the users table */
  
    .search-input {
      width: 100%;
      max-width: 50.5rem; /* Increased max-width to make the search bar a little bigger */
      border: 1px solid #ccc;
      border-radius: 4px;
      padding: 0.5rem;
    }

    .button-group {
      display: flex;
      justify-content: flex-end; /* Aligns buttons to the right */
      gap: 0.5rem; /* Reduces the gap between buttons */
    }
  }
  

  .btn-add, .btn-import {
    width: 10rem; /* Fixed width for both buttons */
    height: 3rem; /* Fixed height for both buttons */
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 500;
  }

  .btn-add {
    background-color: #4caf50;
    color: #fff;
  }

  .btn-import {
    background-color: #2563eb;
    color: #fff;
  }
  
.users-table-container {
  max-height: 65.5rem; // Set a fixed height for the table container
  overflow-y: auto;  // Enable vertical scrolling
  margin: 0 auto;
  // border: 1px solid #ccc;
  width: 100%;
  max-width: 120rem; // Aligns with the existing table width
  background: white;
  position: relative; // Added to position the table header
}

.users-table-container::-webkit-scrollbar {
  width: 5px;
  background-color: #f4f5f9;
  }
  .users-table-container::-webkit-scrollbar-thumb {
  background-color: #d3dae1;
  border-radius: 100px;
  }

// Move the existing .users-table styles inside the new container
.users-table {
    width: 100%;
    border-collapse: collapse;

    thead th {
        font-size: 1.4rem;
        background-color: #223150;
        color: #ffffff;
        font-weight: 600;
        padding: 1rem;
        border: 1px solid #ccc;
        text-align: left;
        position: sticky; // Added to make the header sticky
        top: 0; // Stick to the top
        z-index: 1; // Ensure it stays above the table rows
    }

    .table-row {
        &:hover {
            background: #f0f0f0;
        }
    }

    .table-cell {
        font-size: 1.4rem;
        border: 1px solid #ccc;
        padding: 1rem;
    }

    .action-cell {
        display: flex;
        gap: 0.5rem;
        justify-content: center;
    }

    .no-users-cell {
        text-align: center;
        color: #999;
        font-size: 2rem;
    }
}

  .table-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 250px;
  }
  
  .btn-db-edit, .btn-db-delete {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    width: 4rem; /* Fixed width */
    height: 4rem; /* Fixed height */
  
    .icon {
      height: 1.5rem;
      width: 1.5rem;
    }
  }
  
  .btn-db-edit {
    background-color: #223150;
    color: #fff;

    &:hover {
      background-color: lighten(#223150, 10%);
    }
  }
  
  .btn-db-delete {
    background-color: #fc444a;
    color: #fff;

    &:hover {
      background-color: lighten(#fc444a, 10%);
    }
  }

  .new-user-input {
    width: 95%;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 0.5rem 1rem; /* Updated padding on the left and right */
    font-size: 1rem;
    color: #333;
    background-color: #f9f9f9;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
    display: block;
    margin: 0 auto; /* Center align the input field */
  }

  .new-user-label {
    font-size: 2.5rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
  }

  .add-user-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 10px;
    
  }

  .upload-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    background-color: #4caf50;
    color: #fff;
    font-size: 1rem;
    font-weight: 500;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #45a049;
    }

    .icon {
      height: 1rem;
      width: 1rem;
      margin-right: 0.5rem;
    }
  }

  .add-user-button {
    font-size: 1.5rem;
    display: flex;
    justify-content: center;
    margin-top: 1rem;
  }

  .pagination {
    display: flex;
    justify-content: right; /* Center align the pagination controls */
    margin: 1rem auto 0;
    max-width: 120rem;
    gap: 1rem;
    padding-bottom: 1rem;

    .pagination-buttons {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 1rem;
    }
    
    .pagination-controls {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .pagination-ellipsis {
      font-size: 1.5rem;
      font-weight: 500;
    }

    
  }
  
  .form-label {
    display: block;
    font-weight: 500;
    margin-bottom: 0.5rem;
  }
  
  .form-input {
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 0.5rem;
  }

  .form-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;

    .form-group {
        flex: 1;
        margin-right: 1rem;

        &:last-child {
            margin-right: 0;
        }
    }
}
  
  
  .cancel-button {
    background: #e5e7eb;
    color: #000;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    border: none;
  }
  
  .save-button {
    background: #2563eb;
    color: #fff;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    border: none;
  }

  .database-modal-body {
    padding: 3rem !important; 
    // display: flex;
  }